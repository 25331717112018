import Image from "next/image";
import * as styles from "./WelcomeImage.styles";

export default function WelcomeImage({ image }: { image?: string }) {
  return (
    <div css={styles.imageContainer}>
      {image && (
        <Image
          src={image}
          alt="Landing page image"
          width="595"
          height="740"
          css={styles.image}
        />
      )}
    </div>
  );
}
