import { theme } from "@comunidadfeliz/theme";
import { css } from "@emotion/react";

export const imageContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  color: ${theme.colors.white};
`;

export const gradient = css`
  inset: 0;
  position: absolute;
  padding: 72px 40px;
  background: linear-gradient(
    180deg,
    var(--color-blue) 0%,
    rgba(255, 0, 0, 0) 100%
  );
  > h1 {
    font-size: 54px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 0.25px;
  }
`;

export const image = css`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
`;
