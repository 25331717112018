import { THomeImageConfig } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const defaultConfig: THomeImageConfig = {
  owner: {
    desktopUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
    tabletUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
    mobileUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
  },
  payer: {
    desktopUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
    tabletUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
    mobileUrl: process.env.NEXT_PUBLIC_LANDING_IMAGE,
  },
};
