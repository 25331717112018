import Link from "next/link";
import { useTranslation } from "react-i18next";
import Button from "@comunidadfeliz/button";

import {
  CommonExpenseIcon,
  DesktopLogoLanding,
  LeaseIcon,
  MobileLogoLanding,
} from "assets";
import { useAnalytics, useAuth } from "hooks";
import { useAppDispatch } from "store";
import { CategoryEnum } from "types";
import { TABLET_BREAKPOINT } from "styles";
import { ANALYTIC_EVENTS, bannerUrl } from "utils";
import { FooterCF } from "features/layout";
import { setCategory as setPropertyCategory } from "features/lessee/onboarding/index.slice";
import * as styles from "./WelcomeSection.styles";
import { setCategory, setPaymentGatewayId } from "../form/form.slice";

export default function WelcomeSection() {
  const { redirect } = useAuth();
  const { logEvent } = useAnalytics();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["landing"]);
  const { redirectToLogin } = useAuth();

  const handleSelectCommonExpense = () => {
    redirect.step(1);
    dispatch(setPaymentGatewayId(""));
    dispatch(setCategory(CategoryEnum.CommonExpense));
    dispatch(setPropertyCategory(CategoryEnum.CommonExpense));
  };

  const handleSelectLease = () => {
    redirect.step(1);
    dispatch(setPaymentGatewayId(""));
    dispatch(setCategory(CategoryEnum.Lease));
    dispatch(setPropertyCategory(CategoryEnum.Lease));
  };

  const handleLogin = () => {
    logEvent(ANALYTIC_EVENTS.OWNER_SIGN_IN);
    redirectToLogin();
  };

  return (
    <section css={styles.section}>
      <MobileLogoLanding />
      <DesktopLogoLanding height={64} />
      <div>
        <article css={styles.card}>
          <div css={styles.message}>
            <h1>{t("landing:title")}</h1>
            <p>{t("landing:subtitle")}</p>
          </div>
          <div css={styles.buttons}>
            <button
              id="common-expense"
              css={styles.button}
              type="button"
              onClick={handleSelectCommonExpense}
            >
              <CommonExpenseIcon />
              <span>
                {t("landing:category", { context: CategoryEnum.CommonExpense })}
              </span>
            </button>
            <button
              id="lease"
              css={styles.button}
              type="button"
              onClick={handleSelectLease}
            >
              <LeaseIcon />
              <span>
                {t("landing:category", { context: CategoryEnum.Lease })}
              </span>
            </button>
          </div>
        </article>
        <article css={styles.card}>
          <div css={styles.message}>
            <h1>{t("landing:owner.title")}</h1>
            <Button css={styles.loginButton} onClick={handleLogin}>
              {t("landing:owner.login")}
            </Button>
          </div>
        </article>
        <Link css={styles.appBannerImage} href={bannerUrl()}>
          <picture>
            <source
              media={`(min-width: ${TABLET_BREAKPOINT})`}
              srcSet="app-banner-desktop.png"
            />
            <img src="app-banner-mobile.png" alt="app-banner" />
          </picture>
        </Link>
        <FooterCF />
      </div>
    </section>
  );
}
