import { BaseConfig } from "types";

export const landingConfig: BaseConfig = {
  i18nNamespaces: [
    "common",
    "landing",
    "promotionalModals",
    "cfPayment",
    "owner",
  ],
};
