import { GetStaticProps } from "next";
import { useTranslation } from "react-i18next";

import { getServerTranslations } from "i18n";
import { Head, styles } from "features/layout";
import { WelcomeImage, WelcomeSection, landingConfig } from "features/landing";
import useFirebaseRemoteConfig from "features/firebaseRemoteConfig/useFirebaseRemoteConfig";
import useBciScriptLoader from "features/bci/useBciScriptLoader";

function Index() {
  const { t } = useTranslation(["landing"]);
  const { landingImage } = useFirebaseRemoteConfig();
  useBciScriptLoader();

  return (
    <>
      <Head>{t("landing:pageTitle")}</Head>
      <main css={styles.landingPage}>
        <WelcomeSection />
        <WelcomeImage
          image={
            landingImage.payer?.desktopUrl ??
            process.env.NEXT_PUBLIC_LANDING_IMAGE
          }
        />
      </main>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await getServerTranslations(locale, landingConfig.i18nNamespaces)),
  },
});

export default Index;
