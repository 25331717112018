import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import {
  fetchAndActivate,
  getRemoteConfig,
  getAll,
} from "firebase/remote-config";
import { getGraphqlErrorMessage } from "utils";
import { defaultConfig } from "./defaultConfig";
import { THomeImageConfig } from "./types";

export default function useFirebaseRemoteConfig() {
  const [landingImage, setLandingImage] =
    useState<THomeImageConfig>(defaultConfig);
  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  };
  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  // fetch new info every 1 hour
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  remoteConfig.defaultConfig = {
    defaultModal: JSON.stringify(defaultConfig),
  };

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const all = getAll(remoteConfig) as any;
        setLandingImage(JSON.parse(all.banner._value));
      })
      .catch((err) => {
        getGraphqlErrorMessage(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { landingImage };
}
