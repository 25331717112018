import { theme } from "@comunidadfeliz/theme";
import { css } from "@emotion/react";
import { TABLET_BREAKPOINT } from "styles/breakpoints";

export const section = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  gap: 2rem;
  max-height: 100dvh;
  overflow: auto;
  @media (min-width: ${TABLET_BREAKPOINT}) {
    padding: 0.5rem 1rem;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  > svg {
    margin: 0 auto;
    flex-shrink: 0;
    @media (min-width: ${TABLET_BREAKPOINT}) {
      &:first-of-type {
        display: none;
      }
    }

    @media (max-width: ${TABLET_BREAKPOINT}) {
      &:last-of-type {
        display: none;
      }
    }
  }
`;

export const card = css`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem;
  gap: 1rem;
  color: ${theme.colors.gray.primary};
  box-shadow: 0px 1px 2px 0px ${theme.colors.gray.quaternary}80;

  @media (min-width: ${TABLET_BREAKPOINT}) {
    width: 100%;
    max-width: 530px;
    padding: 1rem 2.5rem;
    gap: 2.5rem;
  }
`;

export const message = css`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  > h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  > p {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
`;

export const buttons = css`
  display: flex;
  gap: 2rem;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    gap: 1rem;
  }
`;

export const button = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid ${theme.colors.gray.quaternary};
  border-radius: 4px;
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: ${theme.colors.gray.tertiary};
    cursor: pointer;
  }
`;

export const loginButton = css`
  background-color: var(--color-buttons);
  @media (min-width: ${TABLET_BREAKPOINT}) {
    margin: 0 auto;
  }
  &:focus,
  :hover {
    background-color: var(--color-buttons-hover) !important;
  }
  &:active {
    background-color: var(--color-buttons-active) !important;
  }
`;

export const appBannerImage = css`
  display: flex;
  > picture {
    width: 100%;
    > img {
      width: 100%;
      object-fit: cover;
    }
  }
`;
